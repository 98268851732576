<template>
  <div class="container-fluid">
    <form class="row" role="form">
      <div class="col-md-6">
        <div class="form-group">
          <label>Rodzaj sklepu</label>
          <v-select
            name="shop_type"
            label="name"
            id="rks"
            v-model="location.sub"
            :options="shopTypes"
            v-validate="'required'"
            :reduce="shopType => shopType.value"
          ></v-select>
          <small v-show="errors.has('shop_type')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Nazwa</label>
          <input
            type="text"
            class="form-control"
            v-model="location.name"
            name="nazwa"
            v-validate="'required'"
          />
          <small v-show="errors.has('nazwa')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Ulica</label>
          <input
            type="text"
            class="form-control"
            v-model="location.street"
            name="street"
            placeholder="Dodaj skrót np. ul, al, itd."
            v-validate="{
              required: location.visible === true || location.visible === 1
            }"
          />
          <small v-show="errors.has('street')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Kod pocztowy</label>
          <input
            type="text"
            class="form-control"
            v-model="location.post_code"
            name="post_code"
            id="post-code"
          />
          <small v-show="errors.has('post_code')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Miasto</label>
          <div class="row mb-1">
            <div class="col-12 col-md-8">
              <v-select
                name="cities_id"
                id="cities_id"
                v-model="location.city"
                v-validate="{
                  required: location.visible === true || location.visible === 1
                }"
                label="name"
                :options="cityList"
                :reduce="city => city.id"
              ></v-select>
            </div>
            <div class="col-12 col-md-4">
              <div
                v-if="!show_add_city"
                class="btn btn-primary btn-md w-100"
                @click="showAddCity"
              >
                Dodaj miasto
              </div>
              <div
                v-else
                class="btn btn-danger btn-md w-100"
                @click="hideAddCity"
              >
                Anuluj
              </div>
            </div>
          </div>
          <div class="row" v-if="show_add_city">
            <div class="col-12 col-md-8">
              <input
                class="form-control m-b"
                name="city"
                v-model="add_new_city"
                placeholder="Tutaj wpisz nazwę miasta. Uwzględnij polskie znaki"
              />
            </div>
            <div class="col-12 col-md-4">
              <div class="btn btn-primary btn-md w-100" @click="addCity">
                Dodaj
              </div>
            </div>
          </div>
          <small v-show="errors.has('cities_id')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Województwo</label>
          <v-select
            name="voivodeship"
            id="voivodeship"
            v-model="location.voivodeship"
            v-validate="{
              required: location.visible === true || location.visible === 1
            }"
            label="name"
            :options="voivodeshipsList"
            :reduce="district => district.id"
          ></v-select>
          <small v-show="errors.has('voivodeship')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Telefon</label>
          <input type="text" class="form-control" v-model="location.phone" />
        </div>
        <div class="form-group">
          <label>Email</label>
          <input type="text" class="form-control" v-model="location.email" />
        </div>
      </div>
      <div class="col-md-6">
        <div style="padding: 5px" class="form-group"></div>
        <div class="form-group row">
          <div class="col-6">
            <label class="d-flex">Etykieta weekend</label>
            <label
              ><small>Etykiety: Pn. Wt. Śr. Czw. Pt. Sob. Niedz.</small></label
            >
            <input
              type="text"
              class="form-control"
              v-model="location.label_weekend"
            />
          </div>
          <div class="col-6">
            <label class="d-flex">Godziny otwarcia weekend</label>
            <label><small>Godziny format: 09:00 - 20:00</small></label>
            <input
              type="text"
              class="form-control"
              v-model="location.opening_hours_weekend"
              name="godzWeek"
              placeholder="Pamiętaj o podaniu godzin w poprawnym formacie podanym wyżej"
            />
            <small v-show="errors.has('godzWeek')" class="text-danger">
              Pole wymagane!
            </small>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-6">
            <label class="d-flex">Etykieta dni robocze</label>
            <label
              ><small>Etykiety: Pn. Wt. Śr. Czw. Pt. Sob. Niedz.</small></label
            >
            <input
              type="text"
              class="form-control"
              v-model="location.label_working_days"
            />
          </div>
          <div class="col-6">
            <label class="d-flex">Godziny otwarcia dni robocze</label>
            <label><small>Godziny format: 09:00 - 20:00</small></label>
            <input
              type="text"
              class="form-control"
              v-model="location.opening_hours_working_days"
              name="godzRob"
              placeholder="Pamiętaj o podaniu godzin w poprawnym formacie podanym wyżej"
            />
            <small v-show="errors.has('godzRob')" class="text-danger">
              Pole wymagane!
            </small>
          </div>
        </div>
        <div class="form-group">
          <div @click="downloadGoogle()" class="btn btn-primary">
            Pobierz współrzędne geograficzne
          </div>
        </div>
        <div class="form-group">
          <label>Długość geograficzna</label>
          <input
            name="longitude"
            type="text"
            class="form-control"
            v-model="location.latitude"
            v-validate="{
              required: location.visible === true || location.visible === 1
            }"
          />
          <small v-show="errors.has('longitude')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Szerokość geograficzna</label>
          <input
            name="latitude"
            type="text"
            class="form-control"
            v-model="location.longitude"
            v-validate="{
              required: location.visible === true || location.visible === 1
            }"
          />
          <small v-show="errors.has('latitude')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div v-if="this.$route.params.id" class="form-check">
          <input
            v-model="location.visible"
            class="form-check-input"
            id="visible"
            type="checkbox"
          />
          <label for="visible">Widoczność</label>
        </div>
      </div>
    </form>
    <div class="form-group">
      <div @click="saveChange" class="btn btn-primary">Zapisz lokalizacje</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      location: {
        sub: "1",
        name: "",
        street: "",
        post_code: "",
        city: "",
        voivodeship: "",
        phone: "",
        email: "",
        longitude: "",
        latitude: "",
        label_weekend: "",
        opening_hours_weekend: "",
        label_working_days: "",
        opening_hours_working_days: "",
        visible: 1
      },
      shopTypes: [
        {
          value: "1",
          name: "Punkt Neonail"
        },
        {
          value: "2",
          name: "Perfumeria Douglas"
        },
        {
          value: "3",
          name: "Drogeria"
        },
        {
          value: "4",
          name: "Hebe"
        }
      ],
      cityList: [],
      voivodeshipsList: [],
      show_add_city: false,
      add_new_city: ""
    };
  },
  created() {
    this.$emit("loading", false);
    this.getData();
  },
  methods: {
    getData() {
      this.getDistrictList();
      this.getCitiesList();
      if (this.$route.params.id) {
        this.$emit("loading", true);
        this.$http
          .get(
            `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/google-maps/${this.$route.params.id}`
          )
          .then(resp => {
            this.location.sub = resp.data[0].sub;
            this.location.city =
              resp.data[0].city_id !== 0 ? resp.data[0].city_id : "";
            this.location.name = resp.data[0].name;
            this.location.street = resp.data[0].street;
            this.location.post_code = resp.data[0].post_code;
            this.location.longitude = resp.data[0].longitude;
            this.location.latitude = resp.data[0].latitude;
            this.location.voivodeship =
              resp.data[0].voivodeship_id !== 0
                ? resp.data[0].voivodeship_id
                : "";
            this.location.opening_hours_weekend =
              resp.data[0].opening_hours_weekend;
            this.location.opening_hours_working_days =
              resp.data[0].opening_hours_working_days;
            this.location.phone = resp.data[0].phone;
            this.location.email = resp.data[0].email;
            this.location.label_weekend = resp.data[0].label_weekend;
            this.location.label_working_days = resp.data[0].label_working_days;
            if (
              this.location.longitude == "" &&
              this.location.latitude == "" &&
              this.location.city == "" &&
              this.location.voivodeship == ""
            ) {
              this.location.visible = 0;
            }
            this.$emit("loading", false);
          });
      }
    },
    addCity() {
      //dodawanie nowego miasta
      let contains = false;
      this.cityList.forEach(el => {
        if (el.name.toLowerCase() == this.add_new_city.toLowerCase()) {
          this.$toastr.success("Miasto znajduje się już na liście!");
          this.location.city = el.id;
          contains = true;
        }
      });

      if (contains) return;

      this.$http
        .post(
          `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/google-map-categories`,
          {
            name_pl: this.add_new_city,
            sub: "region_city"
          }
        )
        .then(resp => {
          if (resp.data.googleMpaCategory.id !== undefined) {
            this.cityList.push({
              id: resp.data.googleMpaCategory.id,
              name: resp.data.googleMpaCategory.name_pl
            });
            this.location.city = resp.data.googleMpaCategory.id;
            this.hideAddCity();
            this.$toastr.success("Miasto dodane!");
          } else {
            this.$toastr.error("Bład doawania miasta!");
          }
        })
        .catch(() => {
          this.$toastr.error("Bład doawania miasta!");
        });
    },

    showAddCity() {
      this.show_add_city = true;
    },

    hideAddCity() {
      this.add_new_city = "";
      this.show_add_city = false;
    },

    getCitiesList() {
      this.$http
        .get(
          `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/google-map-cities`
        )
        .then(resp => {
          this.cityList = resp.data.cities;
        });
    },

    getDistrictList() {
      this.$http
        .get(
          `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/google-map-voivodeships`
        )
        .then(resp => {
          this.voivodeshipsList = resp.data.voivodeships;
        });
    },
    restartForm() {
      this.location = {
        sub: this.location.sub,
        name: "",
        street: "",
        post_code: "",
        city: "",
        voivodeship: "",
        phone: "",
        email: "",
        longitude: "",
        latitude: "",
        label_weekend: "",
        opening_hours_weekend: "",
        label_working_days: "",
        opening_hours_working_days: "",
        visible: 1
      };
      this.$validator.reset();
    },
    saveChange() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("loading", true);
          if (typeof this.location.voivodeship != Number) {
            this.voivodeshipsList.forEach(el => {
              if (el.name == this.location.voivodeship) {
                this.location.voivodeship = el.id;
                return;
              }
            });
          }
          this.$http
            .customRequest({
              method: this.$route.params.id ? "put" : "post",
              url: this.$route.params.id
                ? `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/google-maps/${this.$route.params.id}`
                : `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/google-maps`,
              data: this.location
            })
            .then(() => {
              this.$emit("loading", false);
              this.$toastr.success("Zapisane!");
              this.restartForm();
              this.$router.push(
                `/${this.$route.params.shop_lang}/${this.$route.params.shop_name}/location`
              );
            })
            .catch(err => {
              this.$toastr.error(err);
              this.$emit("loading", false);
            });
        }
      });
    },

    downloadGoogle() {
      let name = this.location.name || "";
      let city = this.location.city || "";
      let street = this.location.street || "";
      let postCode = this.location.post_code || "";
      let url;

      this.cityList.forEach(el => {
        if (el.id == city) {
          city = el.name;
          return;
        }
      });
      if (name.length > 1 && city.length > 1 && street.length > 1) {
        url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/google-maps/addresses?name=${name}&city=${city}&street=${street}`;
        url += postCode.length > 1 ? `&post_code=${postCode}` : "";
      } else if (
        name.length > 1 &&
        (city.length <= 1 || street.length <= 1 || postCode.length <= 1)
      ) {
        url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/google-maps/addresses?name=${name}`;
      } else if (name.length <= 1 && city.length > 1 && street.length > 1) {
        url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/google-maps/addresses?city=${city}&street=${street}`;
        url += postCode.length > 1 ? `&post_code=${postCode}` : "";
      } else {
        this.$toastr.warning(
          "Uzupełnij nazwę lub ulicę, miasto aby pobrać współrzędne"
        );
      }

      if (url) {
        this.$emit("loading", true);
        this.$http
          .get(url)
          .then(resp => {
            if (resp.data.data !== false) {
              this.location.visible = 1;
              this.location.post_code =
                resp.data.data.post_code !== undefined
                  ? resp.data.data.post_code
                  : this.location.post_code;
              if (resp.data.data.voivodeship !== undefined) {
                this.voivodeshipsList.forEach(el => {
                  if (el.name == resp.data.data.voivodeship.toLowerCase()) {
                    this.location.voivodeship = el.id;
                    return;
                  }
                });
              }
              if (resp.data.data.city !== undefined) {
                this.cityList.forEach(el => {
                  if (el.name == resp.data.data.city.toLowerCase()) {
                    this.location.city = el.id;
                    return;
                  }
                });
              }
              this.location.longitude =
                resp.data.data.longitude !== undefined
                  ? resp.data.data.longitude
                  : "";
              this.location.latitude =
                resp.data.data.latitude !== undefined
                  ? resp.data.data.latitude
                  : "";
              this.$toastr.success("Współrzędne uzupełnione");
              this.$emit("loading", false);
            } else {
              this.$toastr.error("Nie odnaleziono wpsółrzednych.");
              this.$emit("loading", false);
            }
          })
          .catch(err => {
            this.$emit("loading", false);
            this.$toastr.error(err);
          });
      }
    }
  }
};
</script>

<style scoped>
.line-height-08 {
  line-height: 0.8rem;
}
input::placeholder {
  font-size: 10px;
}
</style>
